import { ImageAPIResult, LocationAPIResult } from './commonTypes';
import { CompanyAPIResult } from './companyTypes';
import { PropertyAPIResult } from './propertyTypes';

export enum PropertyGroupStatus {
  Unpublished = 1,
  Shareable = 2,
  Published = 3,
  WaitingList = 4,
}

export interface EmbeddedDetailsAPIResult {
  apply: boolean;
  enabled: boolean;
  internal: boolean;
  isometric: boolean;
  isometricImages: ImageAPIResult[];
  key: string;
  propertyListLength: number;
}

export interface OpenHouseDetailsAPIResult {
  enabled: boolean;
  location: LocationAPIResult;
  videoLink: string;
  langToDescription: { [key: string]: string };
}
export interface PropertyGroupAPIResult {
  id: string;
  name: string;
  images: ImageAPIResult[];
  imagesDefault: ImageAPIResult[];
  location: LocationAPIResult;
  slug: string;
  status: number;
  langToSummary: { [key: string]: string };
  langToDescription: { [key: string]: string };
  langToAnnouncement: { [key: string]: string };
  langToIntroMessage: { [key: string]: string };
  langToMetaTitle: { [key: string]: string };
  langToMetaDescription: { [key: string]: string };
  embeddedDetails: EmbeddedDetailsAPIResult;
  videoLink: string;
  openHouseDetails: OpenHouseDetailsAPIResult;
  ownerId: string;
  type: string;
  emailOnlyApplications: boolean;
  selfService: boolean;
  interestListHidden: boolean;
  interestListId: string;
  propertyListLength: number;
  showingsDisabled: boolean;
  externalShowingsEnabled: boolean;
  externalShowingsUrl: string;
  companyOverwriteName: string;
  companyOverwriteLogo: ImageAPIResult;
  customizeContactDetails: boolean;
  upcomingProject: boolean;
  handledBy: string;
  featured: boolean;
  treePlanting: boolean;
}

export interface PropertyGroupSearchAPIResult {
  propertyGroup: PropertyGroupAPIResult;
  properties: PropertyAPIResult[];
  owner: CompanyAPIResult;
}

export interface PropertiesRecapAPIResult {
  location: LocationAPIResult;
  hasProperties: boolean;
  typesForRentAndOrSale: number[];
  propertiesSizeMin: number;
  propertiesSizeMax: number;
  propertiesRoomMin: number;
  propertiesRoomMax: number;
  propertiesYearOfBuildMin: number;
}

export interface PropertyGroupSharedLinkAPIResult {
  data: {
    propertyStatuses: number[];
  } | null;
}
